<template>
  <div class="date-picker">
    <!-- Campo de texto -->
    <input
      type="text"
      v-model="formattedDate"
      readonly
      class="date-input"
      placeholder="Selecciona tu fecha"
      @focus="togglePicker(true)"
    />

    <!-- Date Picker -->
    <div v-if="showPicker" class="picker-dropdown">
      <!-- Día -->
      <div class="picker-column">
        <ul ref="daysList">
          <li
            v-for="(day, index) in infiniteDays"
            :key="index"
            :class="{ active: index === selectedDay }"
            @click="selectDay(index)"
          >
            {{ day }}
          </li>
        </ul>
      </div>

      <!-- Mes -->
      <div class="picker-column">
        <ul ref="monthsList">
          <li
            v-for="(month, index) in infiniteMonths"
            :key="index"
            :class="{ active: index === selectedMonth }"
            @click="selectMonth(index)"
          >
            {{ month }}
          </li>
        </ul>
      </div>

      <!-- Año -->
      <div class="picker-column">
        <ul ref="yearsList">
          <li
            v-for="(year, index) in infiniteYears"
            :key="index"
            :class="{ active: index === selectedYear }"
            @click="selectYear(index)"
          >
            {{ year }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Días, meses y años iniciales
      days: Array.from({ length: 31 }, (_, i) => i + 1),
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      years: Array.from({ length: 125 }, (_, i) => new Date().getFullYear() - i), // Desde 1900 hasta el año actual

      // Estado de la selección
      selectedDay: 0,
      selectedMonth: 0,
      selectedYear: 0,

      showPicker: false, // Controla la visibilidad del picker
    };
  },
  computed: {
    // Formato de fecha: "Día Mes Año"
    formattedDate() {
      return `${this.days[this.selectedDay]} ${this.months[this.selectedMonth]} ${
        this.years[this.selectedYear]
      }`;
    },
    // Datos infinitos para el scroll (duplicados para crear el efecto de bucles)
    infiniteDays() {
      return [...this.days, ...this.days, ...this.days];
    },
    infiniteMonths() {
      return [...this.months, ...this.months, ...this.months];
    },
    infiniteYears() {
      return [...this.years, ...this.years, ...this.years];
    },
  },
  methods: {
    // Toggle para mostrar/ocultar el picker
    togglePicker(state) {
      this.showPicker = state;
    },

    // Selección de día
    selectDay(index) {
      this.selectedDay = index % this.days.length;
      this.closePicker();
    },

    // Selección de mes
    selectMonth(index) {
      this.selectedMonth = index % this.months.length;
      this.closePicker();
    },

    // Selección de año
    selectYear(index) {
      this.selectedYear = index % this.years.length;
      this.closePicker();
    },

    // Cerrar el picker después de la selección
    closePicker() {
      this.showPicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  position: relative;
  display: inline-block;
  width: 100%;
}

.date-input {
  width: 250px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.picker-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 10px;
  border-radius: 4px;
}

.picker-column {
  flex: 1;
  height: 200px; /* Altura del área visible */
  overflow: hidden; /* Oculta las barras de desplazamiento */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.picker-column ul {
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateY(-50%); /* Centramos el contenido */
  transition: transform 0.3s ease-in-out;
}

.picker-column li {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: #666;
}

.picker-column li.active {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

.picker-column li:hover {
  color: #333;
}

.picker-column::-webkit-scrollbar {
  display: none;
}
</style>
